<template>
	<section class="">
		<v-radio-group v-model="trueResponse">
			<article v-for="(response, index) in responses" :key="response.id">
				<h4>RESPONSE #{{ index + 1 }}</h4>
				<InputTextArea v-model="response.responseText"
					><template #label>Response Text</template></InputTextArea
				>
				<figure
					class="d-flex flex-row align-center justify-space-between"
				>
					<InputTextField v-model="response.score"
						><template #label>Score</template></InputTextField
					>
					<v-radio
						:value="String(response.id)"
						:id="String(response.id)"
						:name="String(response.id)"
						label="This is the correct answer"
						color="black"
						class="mt-4"
					></v-radio>
					<!-- <v-btn
						:disabled="true"
						class="admin-default-transparent-button primary-text"
						>Add to response bank</v-btn
					> -->
				</figure>
			</article>
		</v-radio-group>
	</section>
</template>

<script>
export default {
	name: "NewQuestionResponseTrueFalse",
	components: {},
	props: {
		responses: {
			type: Array,
			require: true
		}
	},
	computed: {},
	watch: {
		trueResponse: function (val) {
			this.setRadioButtons(val);
		}
	},
	data() {
		return {
			trueResponse: null
		};
	},
	methods: {
		setRadioButtons(val) {
			this.$parent.$emit("setValue", {
				key: "responses",
				value: this.responses.map(node => {
					return {
						...node,
						isCorrect: node.id === val
					};
				})
			});
		}
	},
	created() {
		this.responses.forEach(element => {
			if (element.isCorrect) {
				this.trueResponse = String(element.id);
			}
		});
	},
	mounted() {
		this.setRadioButtons(this.trueResponse);
	}
};
</script>

<style></style>
